// @ts-check
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Column from './List'
import ideaStates from '../../configurations/ideaStates'
import { SearchBar } from './SearchBar'
import { SlidePanel } from '../Common/SlidePanel'
import SearchForm from './SearchForm/SearchForm'
import { ExpertIdeaForm } from '../Ideas/Expert/IdeaForm'
import CancelImprovementModal from './CancelImprovementModal'
import { useLanguage } from '../../hooks'
import { handleDrop } from './helpers'

import './kanbanBoard.scss'

/** @typedef {import('../../swagger/types').IdeaResource} IdeaResource */

const KanbanBoard = () => {
  const [showSlidePanel, setShowSlidePanel] = useState(false)
  const [error, setError] = useState({ value: false, message: '' })
  const [panelForm, setPanelForm] = useState(null)
  const [panelTitle, setPanelTitle] = useState('')
  const togglePannel = () => setShowSlidePanel(!showSlidePanel)

  const clearPanel = () => {
    setShowSlidePanel(false)
    setPanelForm(null)
    setPanelTitle('')
  }

  const openFilter = () => {
    setPanelTitle('Filter')
    setPanelForm(<SearchForm />)
    togglePannel()
  }

  // get filters from store as they are needed for update data on save
  const fetchedFilters = useSelector(state => state.dataCommon.ideas.filters)
  const arrayOfUrlsToRefreshData = Object.keys(fetchedFilters)

  const editIdea = item => {
    setPanelTitle(item.title)
    setPanelForm(
      <ExpertIdeaForm
        key={item.id}
        idea={item}
        refreshUrl={arrayOfUrlsToRefreshData}
      />
    )
    togglePannel()
  }

  const [modalOpen, setModalOpen] = useState(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => {
    setModalOpen(false)
    setIdeaToBeCancelled({})
    setError({ value: false, message: '' })
  }
  const [processing, setProcessing] = useState(false)

  const lng = useLanguage()
  const dispatch = useDispatch()

  const [ideaToBeCancelled, setIdeaToBeCancelled] = useState({})
  const [params, setParams] = useState({
    dropResult: { name: '', dropEffect: '' },
    sourceFilterUrl: ''
  })

  /** @param {string} comment */
  const addCommentToCancelledIdea = comment => {
    setProcessing(true)
    const newItem = { ...ideaToBeCancelled, interruptionReason: comment }
    handleDrop(
      newItem,
      params.dropResult,
      params.sourceFilterUrl,
      dispatch,
      lng
    )

    setTimeout(() => {
      closeModal()
      setProcessing(false)
    }, 1000)
  }

  /**
   *
   * @param {IdeaResource} idea The idea to be cancelled.
   * @param {Object} params The params to be used when cancelling the idea.
   * @param {Object} params.dropResult The drop result object.
   * @param {string} params.dropResult.name The new state of the idea.
   * @param {string} params.dropResult.dropEffect The name of drop effect.
   * @param {string} params.sourceFilterUrl The source filter url.
   */

  const saveIdeaToBeCancelled = (idea, params) => {
    setParams({ ...params })
    setIdeaToBeCancelled(idea)
    openModal()
  }

  return (
    <>
      <div className='kanban-board mt-2'>
        <SearchBar handleFilterClick={openFilter} />

        <div className='vf-row mt-2'>
          <Column
            ideaState={ideaStates.new}
            editIdea={editIdea}
            saveIdeaToBeCancelled={saveIdeaToBeCancelled}
          />
          <Column
            ideaState={ideaStates.assigned}
            editIdea={editIdea}
            saveIdeaToBeCancelled={saveIdeaToBeCancelled}
          />
          <Column
            ideaState={ideaStates.completed}
            editIdea={editIdea}
            saveIdeaToBeCancelled={saveIdeaToBeCancelled}
          />
          <Column
            ideaState={ideaStates.interrupted}
            editIdea={editIdea}
            saveIdeaToBeCancelled={saveIdeaToBeCancelled}
          />
        </div>
      </div>
      <CancelImprovementModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        idea={ideaToBeCancelled}
        error={error}
        setError={setError}
        processing={processing}
        addCommentToCancelledIdea={addCommentToCancelledIdea}
      />

      <SlidePanel
        closeBtn
        handleClose={clearPanel}
        noFooter
        show={showSlidePanel}
        size={33}
        title={panelTitle}>
        {panelForm}
      </SlidePanel>
    </>
  )
}

export default KanbanBoard
