// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import UserComponent from '../Components/User'
import { getCurrentUserId } from '../auth'

const User = ({ computedMatch }) => {
  const userId = computedMatch.params.userId || getCurrentUserId()

  return <UserComponent userId={userId} />
}

User.propTypes = {
  computedMatch: PropTypes.object
}

export default User
