// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { ChallengeDetails } from '../Components/Challenges'

function ChallengeDetailsPage({ computedMatch }) {
  const { challengeId } = computedMatch.params

  return <ChallengeDetails challengeId={challengeId} />
}

ChallengeDetailsPage.propTypes = {
  // reactRouter
  computedMatch: PropTypes.object
}

export default ChallengeDetailsPage
