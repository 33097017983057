import React from 'react'
import PropTypes from 'prop-types'
import MarkdownView from 'react-showdown'

const CmsImageBlock = ({ block }) => {
  const imageStyles = {
    height: 250,
    width: 250,
    borderRadius: '50%',
    objectFit: 'cover',
    margin: 'auto'
  }

  return (
    <section
      className={`vf-image-block ${
        block.isReversed ? 'vf-image-block--reversed' : ''
      }`}>
      <div className='vf-image-block__body' style={{ alignItems: 'center' }}>
        <div className='vf-image-block__media'>
          <img
            style={imageStyles}
            src={block.image?.url}
            alt={block.image.alternativeText || block.heading}
          />
        </div>
        <div className='vf-image-block__content'>
          <h3 className='vf-image-block__title'>{block.heading}</h3>
          <div className='vf-image-block__text'>
            <MarkdownView
              markdown={block.content}
              options={{ tables: true, emoji: true }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

CmsImageBlock.propTypes = {
  block: PropTypes.object
}

CmsImageBlock.defaultProps = {
  block: {}
}

export default CmsImageBlock
