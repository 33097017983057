import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Modal from '../DesignSystem/Modal'
import { VfButton } from '../DesignSystem/Buttons'
import { useTranslation } from 'react-i18next'
import LengthCounter from '../Common/LengthCounter'

import './cancelImprovementModal.scss'

const CancelImprovementModal = ({
  modalOpen,
  closeModal,
  error,
  setError,
  idea,
  processing,
  addCommentToCancelledIdea
}) => {
  const { t } = useTranslation()
  const [comment, setComment] = useState('')
  const currentLength = !!comment ? comment.length : 0
  const MAX_LENGTH = 500

  const clearModal = () => {
    closeModal()
    setComment('')
  }

  const confirm = () => {
    if (!comment) {
      setError({ value: true, message: t('forms.comment.required') })
      return
    }
    if (currentLength > MAX_LENGTH) {
      setError({ value: true, message: t('forms.comment.tooLong') })
      return
    }
    addCommentToCancelledIdea(comment)
  }

  return (
    <Modal hidden={!modalOpen} closeModal={clearModal}>
      <p>
        {t('titles.idea')}: <span className='title-name'>{idea?.title}</span>
      </p>
      <p>{t('kanban-board.messages.cancelIdea')}</p>
      <div className='modal__comment-wrapper'>
        <label htmlFor='comment' className='mt-2'>
          {t('common.comment')}
        </label>
        <textarea
          name='comment'
          style={{ border: `1px solid ${error.value ? 'red' : '#ced4da'}` }}
          value={comment}
          onChange={e => {
            setError({ value: false, message: '' })
            setComment(e.target.value)
          }}
        />
        <small className='error'>{error.value && <>{error.message}</>}</small>

        <LengthCounter
          currentLength={currentLength}
          maxLength={MAX_LENGTH}
          cssClass='textarea__counter'
        />
      </div>
      <div className='vf-row mb-2'>
        <VfButton
          text={t('common.cancel')}
          color='secondary'
          disabled={processing}
          onClick={clearModal}
          className='vf-col m-1'
        />

        <VfButton
          text={processing ? t('common.submitting') : t('common.submit')}
          disabled={processing}
          color='primary'
          onClick={confirm}
          className='vf-col m-1'
        />
      </div>
    </Modal>
  )
}

CancelImprovementModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  idea: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  addCommentToCancelledIdea: PropTypes.func.isRequired
}
export default CancelImprovementModal
