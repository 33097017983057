// @ts-check

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { CommentsWithContext } from '../../Common'
import { getCurrentUserId } from '../../../auth'
import { requestPreInit } from '../../../state/actions/other'
import { mergeUrlWithParams } from '../../../helpers/url'
import { useLanguage } from '../../../hooks'

export const Likes = ({ userLiked, likesCount, handleClick }) => (
  <div className='idea-details__rates__likes'>
    <div
      onClick={handleClick}
      className={`idea-details__rates__likes__heart ${userLiked &&
        'idea-details__rates__likes__heart--liked'}`}
    >
      ♥
    </div>
    <div>{likesCount}</div>
  </div>
)

Likes.propTypes = {
  userLiked: PropTypes.bool,
  likesCount: PropTypes.number,
  handleClick: PropTypes.func
}

export const Stars = ({ starsCount, handleChange }) => (
  <div className='idea-details__rates__stars'>
    {[5, 4, 3, 2, 1].map(rate => (
      <React.Fragment key={rate}>
        <input
          type='radio'
          name='rating'
          id={`star-${rate}`}
          value={rate}
          checked={starsCount === rate}
          onChange={handleChange}
        />
        <label htmlFor={`star-${rate}`} />
      </React.Fragment>
    ))}
  </div>
)

Stars.propTypes = {
  starsCount: PropTypes.number,
  handleChange: PropTypes.func
}

const IdeaRates = ({ item, t }) => {
  const currentUserId = getCurrentUserId()
  const lng = useLanguage()
  const dispatch = useDispatch()

  const [likes, setLikes] = useState(item.likedBy.map(item => item.id))
  const [avgRate, setAvgRate] = useState(item.avgRating)

  const currentUserLiked = likes.includes(currentUserId)

  useEffect(() => {
    setLikes(item.likedBy.map(item => item.id))
  }, [item.likedBy])

  useEffect(() => {
    setAvgRate(item.avgRating)
  }, [item.avgRating])

  const addLike = () => {
    const newLikes = [...likes, currentUserId]
    setLikes(newLikes)
  }

  const removeLike = () => {
    const newLikes = likes.filter(item => item !== currentUserId)
    setLikes(newLikes)
  }

  const setLike = () => {
    // wp: optimistic ui in action
    const action = currentUserLiked ? removeLike : addLike
    action()

    const urlKey = currentUserLiked ? 'dislike-idea' : 'like-idea'
    const url = item._links[urlKey].href
    const ideaUrl = item._links.self.href
    const ideaContext = mergeUrlWithParams({ lng })(ideaUrl)

    const dataToInvalidate = [
      { dataType: 'ideas' },
      { dataType: 'idea-details', context: ideaContext }
    ]
    dispatch(requestPreInit(url, dataToInvalidate))
  }

  const setRate = event => {
    const newRating = parseInt(event.target.value)
    const rawUrl = item._links.rate.href
    const url = mergeUrlWithParams({ rating: newRating })(rawUrl)
    const ideaUrl = item._links.self.href
    const ideaContext = mergeUrlWithParams({ lng })(ideaUrl)

    // optimistic ui
    const {
      avgRatingSum,
      avgRatingVotesNumber,
      userDecisions: { ratedIdeaFor }
    } = item

    let newAvgRate
    if (ratedIdeaFor) {
      // user have already rated the idea
      newAvgRate =
        (avgRatingSum - ratedIdeaFor + newRating) / avgRatingVotesNumber
    } else {
      newAvgRate = (avgRatingSum + newRating) / (avgRatingVotesNumber + 1)
    }

    setAvgRate(newAvgRate)

    const dataToInvalidate = [
      { dataType: 'ideas' },
      { dataType: 'idea-details', context: ideaContext }
    ]

    dispatch(requestPreInit(url, dataToInvalidate))
  }

  return (
    <div className='idea-details__rates'>
      <Likes
        userLiked={currentUserLiked}
        likesCount={likes.length}
        handleClick={setLike}
      />

      <div className='mx-2'>|</div>

      <Stars starsCount={Math.round(avgRate)} handleChange={setRate} />

      <div className='mx-2'>|</div>

      <CommentsWithContext t={t} />
    </div>
  )
}

IdeaRates.propTypes = {
  item: PropTypes.object,
  t: PropTypes.func
}

export default IdeaRates
