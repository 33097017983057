import React from 'react'
import PropTypes from 'prop-types'
import { VfCardWithMarkup as VfCard } from '../../Components/DesignSystem/Cards'
import { VfContainer } from '../../Components/DesignSystem/Utils'

// poc implementation, should be dynamically iterated over sub-blocks delivered from api
const CmsImagesSection = ({ block }) => {
  return (
    <section className='vf-container-bleed'>
      <VfContainer>
        <div className='vf-row'>
          <div className='vf-col-sm-12 vf-col-md-6 vf-col-lg-4'>
            <VfCard
              imageUrl={block.image1.url}
              title={block.title1}
              bodyText={block.description1}
            />
          </div>
          <div className='vf-col-sm-12 vf-col-md-6 vf-col-lg-4'>
            <VfCard
              imageUrl={block.image2.url}
              title={block.title2}
              bodyText={block.description2}
            />
          </div>
          <div className='vf-col-sm-12 vf-col-md-6 vf-col-lg-4'>
            <VfCard
              imageUrl={block.image3.url}
              title={block.title3}
              bodyText={block.description3}
            />
          </div>
        </div>
      </VfContainer>
    </section>
  )
}

CmsImagesSection.propTypes = {
  block: PropTypes.object
}

CmsImagesSection.defaultProps = {
  block: {}
}

export default CmsImagesSection
