// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { EditForm } from '../Components/Ideas'

function IdeaEditPage ({ computedMatch }) {
  const { ideaId } = computedMatch.params

  return <EditForm ideaId={ideaId} />
}

IdeaEditPage.propTypes = {
  // reactRouter
  computedMatch: PropTypes.object
}

export default IdeaEditPage
