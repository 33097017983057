import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SwitchLayout from './SwitchLayout'
import { switchLayoutTypes } from '../../configurations'

import './header.scss'

const getHeaderContent = (
  t,
  ideasCount,
  commentsCount,
  likesCount,
  filtered
) => {
  if (!filtered) {
    return `${ideasCount} ${t('common.idea', { count: ideasCount })}`
  }
  return `${ideasCount} ${t('common.idea', {
    count: ideasCount
  })} ${t('searchIdeas.matchingIdea', {
    count: ideasCount
  })} (${commentsCount} ${t('common.comment', {
    count: commentsCount
  })} ${t('common.and')} ${likesCount} ${t('common.like', {
    count: likesCount
  })})`
}

const Header = ({
  changeLayout,
  activeLayout,
  ideasInfo,
  filtered,
  showLeadTime = false
}) => {
  const { t } = useTranslation()

  const { totalElements, totalComments, totalLikes, leadTime } = ideasInfo
  return (
    <>
      <div className='vf-row mb-2 ideas-list__title'>
        <div className='vf-col-md-8 vf-col-lg-10 mt-1'>
          <h3 className='mb-2'>
            {getHeaderContent(
              t,
              totalElements,
              totalComments,
              totalLikes,
              filtered
            )}
          </h3>
        </div>

        {/* Layout Switch */}
        <div className='vf-col-md-4 vf-col-lg-2 mt-1'>
          <SwitchLayout
            layouts={switchLayoutTypes}
            changeLayout={changeLayout}
            activeLayout={activeLayout}
          />
        </div>
      </div>
      {showLeadTime && !!leadTime && (
        <p className='lead-time'>
          <span>{t('forms.searchIdeas.leadTime', { number: leadTime })}</span>
        </p>
      )}
    </>
  )
}

Header.propTypes = {
  changeLayout: PropTypes.func,
  activeLayout: PropTypes.string,
  ideasInfo: PropTypes.object,
  filtered: PropTypes.bool,
  showLeadTime: PropTypes.bool,
  leadTime: PropTypes.number
}

export default Header
