// @ts-check
import { getCurrentUserId } from '../auth'

/* #region Config */
export const getFetchConfigIfNeeded = ({ config }) => !config.payload.language

export const getErrorFromConfig = ({ config }) => config.payload.status

export const getConfig = ({ config }) => config.payload

export const getUrlFromConfig =
  key =>
  ({ config }) => {
    try {
      // console.info('getUrlFromConfig says: Got the url for', key)
      return config.payload._links[key].href
    } catch {
      // console.warn('getUrlFromConfig says: Cannot get url for', key)
      return ''
    }
  }

/* #endregion */

/* #region User */

export const getUserUrl = getUrlFromConfig('profile')

export const getFetchUserIfNeeded =
  id =>
  ({ users }) => {
    if (users.isFetching.includes(id)) {
      return false
    }

    return !users.all.includes(id) || users.invalidated.includes(id)
  }

export const getIsUserFetching = ({ users }) => users.isFetching

export const getUserDetails =
  (id = getCurrentUserId()) =>
  ({ users }) =>
    users.all.includes(id) ? users.payload[id] : null

const getUserById = ({ users }, id) => {
  try {
    return users.payload[id]
  } catch (ex) {
    return null
  }
}

export const getIsCurrentUser = id => getCurrentUserId() === id
export const getIsUserExpert = id => state => {
  const user = getUserById(state, id)
  return user ? user.expertUser : false
}

const settingsTypes = {
  notifications: 'notifications',
  subscriptions: 'subscriptions'
}

const getSettingsUrl =
  type =>
  ({ users }) => {
    const links =
      users.payload[getCurrentUserId()] &&
      users.payload[getCurrentUserId()]._links

    if (links) {
      if (type === settingsTypes.notifications) {
        return links.updateNotifications.href
      } else if (type === settingsTypes.subscriptions) {
        return links.updateSubscriptions.href
      }
    }
    return ''
  }

export const getNotificationsUrl = getSettingsUrl(settingsTypes.notifications)
export const getSubscriptionsUrl = getSettingsUrl(settingsTypes.subscriptions)

export const getUserSubscriptionsIds = ({ users }) => {
  const currentUserId = getCurrentUserId()

  return users.payload[currentUserId]
    ? users.payload[currentUserId].subscriptionsConfiguration
        .filter(sub => sub.activeSubscription)
        .map(sub => sub.challangeId)
    : []
}

export const getUserPhoto =
  userId =>
  ({ users }) => {
    try {
      return users.payload[userId]._links.photo.href
    } catch {
      return ''
    }
  }

/* #endregion */

/* #region Filterables */
const getFilterablesListByKey =
  type =>
  key =>
  ({ dataCommon }) => {
    const ids = dataCommon[type].filters[key] || []
    const items = dataCommon[type].items ? dataCommon[type].items : {}

    return ids.map(id => items[id])
  }

const fetchFilterablesIfNeeded =
  type =>
  key =>
  ({ dataCommon }) => {
    const root = dataCommon[type]
    if (root.isFetching.includes(key)) {
      return false
    }

    return !root.all.includes(key)
  }

export const getIsFetching =
  type =>
  key =>
  ({ dataCommon }) =>
    dataCommon[type].isFetching.includes(key)
/* #endregion */

/* #region Detailed */
const getItemDetailsById =
  key =>
  id =>
  ({ data }) => {
    try {
      return data[key].payload[id]
    } catch {
      return null
    }
  }

const fetchItemIfNeeded =
  key =>
  item =>
  ({ data }) => {
    return !data[key].all.includes(item)
  }
/* #endregion */

/* #region Activities */

export const getActivityTypes = ({ config }) => {
  return config.payload.activityTypes
    ? config.payload.activityTypes.reduce(
        (acc, curr) => ({ ...acc, [curr.toLowerCase()]: curr.toLowerCase() }),
        {}
      )
    : null
}

export const getActivityListUrl = getUrlFromConfig('activities')

export const fetchActivitiesIfNeeded = fetchFilterablesIfNeeded('activities')
export const getActivitiesList = getFilterablesListByKey('activities')
export const getIsActivityFetching = getIsFetching('activities')

export const getPagePrevUrl =
  dataType =>
  key =>
  ({ dataCommon }) => {
    try {
      return dataCommon[dataType].payload[key]._links.prev.href
    } catch {
      return ''
    }
  }
export const getPageNextUrl =
  dataType =>
  key =>
  ({ dataCommon }) => {
    try {
      return dataCommon[dataType].payload[key]._links.next.href
    } catch {
      return ''
    }
  }

const getPaginationSetup =
  dataType =>
  key =>
  ({ dataCommon }) => {
    const payload = dataCommon[dataType].payload[key]
    if (payload) {
      const { pageSize, currentPage, totalPages } = payload
      return { pageSize, currentPage, totalPages }
    }
    return {}
  }

export const getActivitiesPaginationSetup = getPaginationSetup('activities')
export const getActivitiesPagePrevUrl = getPagePrevUrl('activities')
export const getActivitiesPageNextUrl = getPageNextUrl('activities')

export const getIdeasPaginationSetup = getPaginationSetup('ideas')
export const getIdeasPagePrevUrl = getPagePrevUrl('ideas')
export const getIdeasPageNextUrl = getPageNextUrl('ideas')
export const getIdeasAdditionalInfo =
  key =>
  ({ dataCommon }) => {
    const payload = dataCommon.ideas.payload[key]
    if (payload) {
      const {
        totalElements,
        extendedProperties: { totalComments, totalLikes, leadTime }
      } = payload
      return { totalElements, totalComments, totalLikes, leadTime }
    }
  }

/* #endregion */

/* #region Chellenges */
export const getChallengesListUrl = ({ config }) =>
  (config.payload._links && config.payload._links.challengesList.href) || ''

export const fetchChallengesListIfNeeded =
  fetchFilterablesIfNeeded('challenges')
export const getChallengesList = getFilterablesListByKey('challenges')
export const getIsChallengesFetching = getIsFetching('challenges')
export const getCreateChallengeUrl = getUrlFromConfig('challengeCreate-POST')
/* #endregion */

/* #region Challege Details */

/**
 * @param {object} config
 */
export const getChallengeDetailsUrl = getUrlFromConfig('challengeDetails')
export const getChallengeDetailsById = getItemDetailsById('challenge-details')
export const fetchChallengeDetailsIfNeeded =
  fetchItemIfNeeded('challenge-details')

/* #endregion */

/* #region Ideas */

export const getIdeasListUrl = getUrlFromConfig('ideasList')
export const fetchIdeasListIfNeeded = fetchFilterablesIfNeeded('ideas')
export const getIdeasList = getFilterablesListByKey('ideas')
export const getIsIdeasFetching = getIsFetching('ideas')
/* #endregion */

/* #region Idea Details */

/**
 * @param {object} config
 */
export const getIdeaDetailsUrl = ({ config }) =>
  config && config.payload._links ? config.payload._links.ideaDetails.href : ''

/**
 * @param {string} id
 */
export const getIdeaDetailsById = getItemDetailsById('idea-details')
export const fetchIdeaDetailsIfNeeded = fetchItemIfNeeded('idea-details')

/**
 * @param {object} config
 */
export const getCreateIdeaUrl = getUrlFromConfig('createIdea')

/* #endregion */

/* #region Pages */

export const getPageUrl = key => getUrlFromConfig(`sitemap-${key}`)
export const getPageDetails = getItemDetailsById('page-details')
export const fetchPageDetailsIfNeeded = fetchItemIfNeeded('page-details')
export const getIsPageFetching = key => false

/* #endregion */

/* #region Comments */

export const getCommentsListUrl = getUrlFromConfig('comments')
export const fetchCommentsListIfNeeded = fetchFilterablesIfNeeded('comments')
export const getCommentsList = getFilterablesListByKey('comments')
export const getIsCommentsFetching = getIsFetching('comments')

/* #endregion */

/* #region Forms */

export const getFormUrl = key => getUrlFromConfig(`forms-${key}`) // forms-search
export const getFormDetails = getItemDetailsById('forms')
export const fetchFormIfNeeded = fetchItemIfNeeded('forms')
export const getIsFormFetching =
  () =>
  ({ data }) =>
    data.forms.isFetching

/* #endregion */

/* #region Languages */

export const getLanguages = ({ config }) => {
  try {
    return config.payload.languages.map(item => item.toLowerCase())
  } catch (err) {
    return []
  }
}

/* #endregion */

/* #region Notifications */

export const getFetchNotificationsIfNeeded = ({ notifications }) => {
  return !notifications.payload.items
}

export const getUserNotificationsUrl = getUrlFromConfig('notifications')

export const getNotifications = ({ notifications }) => {
  try {
    return Object.values(notifications.payload.items)
  } catch (error) {
    return []
  }
}

export const getIsFetchingNotifications = ({ notifications }) =>
  notifications.isFetching

/* #endregion */

/* #region Tag Categories */
export const getTagCategoriesListUrl = getUrlFromConfig('tagCategories')
export const getTagCategoriesList =
  id =>
  ({ data }) => {
    try {
      return data.tagCategories.payload[id].tagCategories
    } catch (error) {
      return null
    }
  }
export const fetchTagCategoriesIfNeeded = fetchItemIfNeeded('tagCategories')
export const isFetchingTagCategories =
  () =>
  ({ data }) =>
    data.tagCategories.isFetching
/* #endregion */

/* #region Tags */
export const getTagsListUrl = getUrlFromConfig('tags')
export const getTagsList =
  id =>
  ({ data }) => {
    try {
      return data.tags.payload[id].tags
    } catch (error) {
      return null
    }
  }
export const fetchTagsIfNeeded = fetchItemIfNeeded('tags')
export const isFetchingTags =
  () =>
  ({ data }) =>
    data.tags.isFetching
/* #endregion */

// #region departments
export const getDepartmentsListUrl = getUrlFromConfig('departaments')
export const getDepartmentsList =
  id =>
  ({ data }) => {
    try {
      return data.departments.payload[id]
    } catch (error) {
      return null
    }
  }
export const fetchDepartmentsIfNeeded = fetchItemIfNeeded('departments')
export const isFetchingDepartments =
  () =>
  ({ data }) =>
    data.departments.isFetching
// #endregion

export const searchFormParams = ({ searchForm }) => searchForm
export const getPaginationConfig =
  key =>
  ({ paginationConfig }) =>
    paginationConfig[key]

// #region Dashboard
export const getDashboardUrl = getUrlFromConfig('dashboard')
export const getChallengesDashboardUrl = getUrlFromConfig(
  'dashboard-challenges'
)
export const getDepartmentsDashboardUrl = getUrlFromConfig(
  'dashboard-departments'
)

export const getDashboardData =
  id =>
  ({ dataCommon }) => {
    try {
      return dataCommon.dashboard.payload[id]
    } catch (error) {
      return null
    }
  }

export const fetchDashboardIfNeeded = fetchFilterablesIfNeeded('dashboard')
export const isFetchingDashboard = getIsFetching('dashboard')
// #endregion

// #region Attachments upload
export const getAttachmentsUploadUrl = getUrlFromConfig('upload-file-idea')
// #endregion

// #region KPIs
export const getKpisUrl = getUrlFromConfig('get-kpi-factors')
export const getKpisPostUrl = getUrlFromConfig('save-kpi-factors')

export const getKpisList =
  id =>
  ({ data }) => {
    try {
      return data.kpis.payload[id]
    } catch (error) {
      return null
    }
  }

export const fetchKpisIfNeeded = fetchItemIfNeeded('kpis')
export const isFetchingKpis =
  () =>
  ({ data }) =>
    data.kpis.isFetching
// #endregion

// #region Users
export const getAllUsersUrl = getUrlFromConfig('search-all-profiles')
export const getUsersPostUrl = getUrlFromConfig('save-update-profile')

export const fetchUsersListIfNeeded = fetchFilterablesIfNeeded('allUsers')
export const getAllUsersList = getFilterablesListByKey('allUsers')
export const getIsAllUsersFetching = getIsFetching('allUsers')

export const getAllUsersPaginationSetup = getPaginationSetup('allUsers')
export const getAllUsersPagePrevUrl = getPagePrevUrl('allUsers')
export const getAllUsersPageNextUrl = getPageNextUrl('allUsers')

// #endregion
