// @ts-check

import React from 'react'
import PropTypes from 'prop-types'

import { CreateForm } from '../Components/Ideas'

const IdeaCreate = ({ computedMatch }) => {
  const { challengeId } = computedMatch.params

  return <CreateForm selectedChallenge={challengeId} />
}

IdeaCreate.propTypes = {
  computedMatch: PropTypes.object
}

export default IdeaCreate
