// @ts-check
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import IdeaItemTemplate from './ItemTemplates'
import actions from '../../state/actions/data'
import {
  getIdeasListUrl,
  getIdeasList,
  getIsIdeasFetching,
  getIdeasPagePrevUrl,
  getIdeasPageNextUrl,
  getIdeasPaginationSetup,
  getIdeasAdditionalInfo
} from '../../selectors'
import { useData, useLanguage } from '../../hooks'
import { Loading, Pagination } from '../Common'
import Header from './Header'
import { SlidePanel } from '../Common/SlidePanel'
import { ExpertIdeaForm } from './Expert/IdeaForm'
import ideaStates from '../../configurations/ideaStates'

import './ideas.scss'

const List = ({ items, layoutType, sortedByTrendiest, ...props }) =>
  items.map(item => (
    <IdeaItemTemplate
      key={item.id}
      type={layoutType}
      item={item}
      showViews={sortedByTrendiest}
      {...props}
    />
  ))

const ListContainer = ({
  filtered,
  layoutType,
  limit,
  setIsSearching,
  setLayoutType,
  showBottomPagination,
  showHeader,
  showState,
  showTopPagination,
  sortedByTrendiest,
  sortFunc,
  storeKey,
  urlParams
}) => {
  const { t } = useTranslation()
  const lng = useLanguage()

  const showLeadTime =
    urlParams?.status?.length === 1 &&
    urlParams?.status[0] === ideaStates.completed.apiValue

  const [pageUrl, setPageUrl] = useState('')
  const [showSlidePanel, setShowSlidePanel] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const timeout = delay => {
    return new Promise(resolve => setTimeout(resolve, delay))
  }

  const toggleSlidePanel = () => setShowSlidePanel(!showSlidePanel)
  const toggleEditForm = async item => {
    if (item === selectedItem) {
      toggleSlidePanel()
    } else {
      if (showSlidePanel) {
        setShowSlidePanel(false)
        await timeout(500)
      }
      setSelectedItem(item)
      setShowSlidePanel(true)
    }
  }

  const {
    data: items,
    url,
    isFetching
  } = useData(
    'ideas',
    { ...urlParams, lng },
    getIdeasListUrl,
    getIdeasList,
    actions.requestPreInit,
    getIsIdeasFetching,
    pageUrl
  )

  const sortedItems = items.sort(sortFunc)
  const finalItems = limit ? sortedItems.slice(0, limit) : sortedItems

  const prevUrl = useSelector(getIdeasPagePrevUrl(url))
  const nextUrl = useSelector(getIdeasPageNextUrl(url))
  const paginationSetup = useSelector(getIdeasPaginationSetup(url))
  const ideasInfo = useSelector(getIdeasAdditionalInfo(url))

  const handlePageClick = url => {
    setPageUrl(url)
  }

  useEffect(() => {
    setIsSearching(isFetching)
  }, [isFetching, setIsSearching])

  const getListContent = () => {
    if (!finalItems.length && !isFetching) {
      return <p>{t('common.sorryNoItems')}</p>
    } else if (isFetching) {
      return <Loading />
    } else {
      return (
        <>
          {showHeader && (
            <Header
              changeLayout={setLayoutType}
              activeLayout={layoutType}
              ideasInfo={ideasInfo}
              filtered={filtered}
              showLeadTime={showLeadTime}
            />
          )}

          {showTopPagination && (
            <div className='vf-row'>
              <Pagination
                storeKey={storeKey}
                totalPages={paginationSetup.totalPages}
                currentPage={paginationSetup.currentPage + 1}
                isFirst={!prevUrl}
                isLast={!nextUrl}
                handlePrevClick={() => handlePageClick(prevUrl)}
                handleNextClick={() => handlePageClick(nextUrl)}
              />
            </div>
          )}

          <div className='vf-row'>
            <List
              items={finalItems}
              layoutType={layoutType}
              sortedByTrendiest={sortedByTrendiest}
              toggleEditForm={toggleEditForm}
              showState={showState}
            />
          </div>

          {showBottomPagination && (
            <div className='vf-row'>
              <Pagination
                storeKey={storeKey}
                totalPages={paginationSetup.totalPages}
                currentPage={paginationSetup.currentPage + 1}
                isFirst={!prevUrl}
                isLast={!nextUrl}
                handlePrevClick={() => handlePageClick(prevUrl)}
                handleNextClick={() => handlePageClick(nextUrl)}
              />
            </div>
          )}
        </>
      )
    }
  }

  return (
    <>
      {getListContent()}

      <SlidePanel
        show={showSlidePanel}
        closeBtn
        noFooter
        handleClose={toggleSlidePanel}
        title={selectedItem?.title}>
        {!!showSlidePanel && (
          <ExpertIdeaForm idea={selectedItem} refreshUrl={url} />
        )}
      </SlidePanel>
    </>
  )
}

ListContainer.propTypes = {
  urlParams: PropTypes.object,
  layoutType: PropTypes.string,
  setLayoutType: PropTypes.func,
  limit: PropTypes.number,
  sortFunc: PropTypes.func,
  showHeader: PropTypes.bool,
  setIsSearching: PropTypes.func,
  filtered: PropTypes.bool,
  sortedByTrendiest: PropTypes.bool,
  showTopPagination: PropTypes.bool,
  showBottomPagination: PropTypes.bool,
  showState: PropTypes.bool,
  storeKey: PropTypes.string
}

ListContainer.defaultProps = {
  urlParams: {},
  limit: null,
  sortFunc: () => {},
  setLayoutType: () => {},
  showHeader: false,
  setIsSearching: () => {},
  filtered: false,
  sortedByTrendiest: false,
  showTopPagination: false,
  showBottomPagination: false,
  showState: false
}

export default ListContainer
