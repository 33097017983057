// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { IdeaDetails } from '../Components/Ideas'

function IdeaDetailsPage ({ computedMatch }) {
  const { ideaId } = computedMatch.params

  return <IdeaDetails ideaId={ideaId} />
}

IdeaDetailsPage.propTypes = {
  // reactRouter
  computedMatch: PropTypes.object
}

export default IdeaDetailsPage
