// #region Session Storage
import actions from '../../state/actions/dnd'
import { mergeUrlWithParams } from '../../helpers/url'
import { tmpGetUrl } from '../Ideas/Expert/IdeaForm/EditForm'

const sessionKey = 'kanban-board-search'

/**
 * @param {Object} value
 * @param {string} [key]
 * @returns {void}
 */
export const saveToSessionStorage = (value, key = sessionKey) => {
  sessionStorage
    ? sessionStorage.setItem(key, JSON.stringify(value))
    : console.error('Session Storage not supported')
}

/**
 * @param {string} [key]
 * @returns {Object} value as Object
 */
export const getFromSessionStorage = (key = sessionKey) => {
  return sessionStorage ? JSON.parse(sessionStorage.getItem(key)) : null
}

/**
 * @param {string} [key]
 * @returns {void}
 */
export const removeFromSessionStorage = (key = sessionKey) => {
  sessionStorage.removeItem(key)
}

/**
 * Turn IdeaResource to UpdateIdeaRequest that can be send to API.
 * @param {IdeaResource} item
 * @returns {UpdateIdeaRequest} Item in format that can be send to API
 */
const uiItemToApiItem = item => {
  const {
    activities = '',
    assignee: { id: assignee = '' } = {},
    challenge,
    deadline = '',
    description = '',
    ideaState,
    ideaValue = '',
    solution = '',
    tags: tagsObj = [],
    title,
    interruptionReason
  } = item

  const challengeId = challenge?.id || ''
  const createPublished = false
  const tags = tagsObj.map(tag => tag.id)

  return {
    activities,
    assignee,
    challengeId,
    createPublished,
    deadline,
    description,
    ideaState,
    ideaValue,
    solution,
    tags,
    title,
    interruptionReason
  }
}
/**
 * @param {IdeaResource} item - id of item to be updated
 * @param {Object} dropResult
 * @param {IdeaStates} dropResult.name - new state of item
 * @param {string} sourceFilterUrl - filter url of source
 * @param {Function} dispatch - dispatch function
 * @param {string} lng - language
 */
export const handleDrop = (
  item,
  { name: newState },
  sourceFilterUrl,
  dispatch,
  lng
) => {
  const { id = '', _links: links, ideaState: oldState } = item

  /** @type {IdeaResource} */
  const updatedItem = { ...item, ideaState: newState }
  /** @type {UpdateIdeaRequest} */
  const apiItem = uiItemToApiItem(updatedItem)
  const ideaUrl = links?.self?.href || ''
  const ideaContext = mergeUrlWithParams({ lng })(ideaUrl)
  const dataToInvalidate = [
    { dataType: 'ideas' },
    { dataType: 'idea-details', context: ideaContext },
    { dataType: 'dashboard' },
    { datatype: 'comments', context: ideaContext }
  ]

  // TODO: url should be taken from idea._links.update.href
  const url = tmpGetUrl(id)

  const source = item.filterUrls?.target || sourceFilterUrl
  const filterUrls = {
    source,
    target: source
      // update target filter with new state
      .replace(oldState, newState)
      // reset page to 0 when changing state to avoid pagination issues (#61198)
      .replace(/(page=)[0-9]+/, '$1' + '0')
  }

  dispatch(
    actions.updateDataInit(
      // @ts-ignore
      // pass filterUrls to item into state as cannot find another way to track source and target filters
      { ...updatedItem, filterUrls },
      apiItem,
      url,
      dataToInvalidate,
      filterUrls
    )
  )
}
