import React from 'react'
import PropTypes from 'prop-types'

const TitleByType = ({ type, children }) => {
  const className = 'pt-4 pb-3'
  switch (type.toLowerCase()) {
    case 'h1':
      return <h1 className={className}>{children}</h1>
    case 'h2':
      return <h2 className={className}>{children}</h2>
    case 'h3':
      return <h3 className={className}>{children}</h3>
    case 'h4':
      return <h4 className={className}>{children}</h4>
    case 'h5':
      return <h5 className={className}>{children}</h5>
    default:
      return null
  }
}

TitleByType.propTypes = {
  type: PropTypes.string,
  children: PropTypes.string
}

TitleByType.defaultProps = {
  block: ''
}

const CmsTitle = ({ block }) => {
  return (
    <section className='vf-container vf-container--tight'>
      <div className='vf-text--center'>
        <TitleByType type={block.headlineType}>{block.title}</TitleByType>

        <div clclassNameass='vf-preamble pt-sm-2'>
          <p>{block.subtitle}</p>
        </div>
      </div>
    </section>
  )
}

CmsTitle.propTypes = {
  block: PropTypes.object
}

CmsTitle.defaultProps = {
  block: {}
}

export default CmsTitle
