// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Avatar from '../User/Avatar'
import { UserLink, IdeaLink, Tags } from '../Common'
import { StarsPainter } from '../Common/DetailsHelpers'
import { truncateStr } from '../../helpers/string'
import { useDrag } from 'react-dnd'
import { useLanguage } from '../../hooks'
import { icons } from '../../assets/icons'
import { onlyDateLocal } from '../../helpers/date'
import ideaStates from '../../configurations/ideaStates'
import { handleDrop } from './helpers'

/** @typedef {import('../../swagger/types').IdeaResource} IdeaResource */
/** @typedef {import('../../swagger/types').IdeaStates} IdeaStates */
/** @typedef {import('../../swagger/types').UpdateIdeaRequest} UpdateIdeaRequest */

/**
 * @param {Object} props
 * @param {IdeaResource} props.item
 * @param {Function} props.editIdea
 * @returns {JSX.Element}
 */
const Item = ({ item, editIdea }) => {
  const { t } = useTranslation()

  const {
    id: ideaId = '',
    deadline,
    title,
    starsCount = 0,
    tags = [],
    assignee,
    activities
  } = item
  const { id: assigneeId = '', displayName: assigneeName = '' } = assignee || {}
  const safeTitle = truncateStr(title, 120)
  const hasAssignee = !!Object.keys(assignee || {}).length

  return (
    <>
      <div className='kanban-board__item__top'>
        <div className='kanban-board__item__top-wrapper'>
          <p className='kanban-board__item__title'>{safeTitle}</p>
          {!!deadline && (
            <p className='kanban-board__item__deadline'>
              {t('common.deadline')}: {onlyDateLocal(deadline)}
            </p>
          )}
        </div>
        {hasAssignee && (
          <div className='kanban-board__item__profile-container'>
            {/* @ts-ignore */}
            <UserLink id={assigneeId} title={assigneeName}>
              <Avatar author={assignee} />
            </UserLink>
          </div>
        )}
      </div>
      {/* /top */}

      <div className='kanban-board__item__rating'>
        {/* @ts-ignore */}
        <StarsPainter rating={starsCount} />
        <div
          onClick={e => {
            e.preventDefault()
            editIdea(item)
          }}
          className='edit-button__icon'>
          <img src={icons.Edit} alt={t('common.edit')} />
        </div>
      </div>
      {/* /rating */}

      <div className='kanban-board__item__tags'>
        {/* @ts-ignore */}
        <Tags items={tags} />
      </div>
      {/* /tags */}
      {!!activities && (
        <div className='kanban-board__item__activities'>{activities}</div>
      )}
      {/* @ts-ignore */}
      <IdeaLink id={ideaId} className='kanban-board__item__link' />
    </>
  )
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  editIdea: PropTypes.func.isRequired
}

/**
 * @param {Object} props
 * @param {Function} props.editIdea
 * @param {string} props.filterUrl
 * @param {IdeaResource} props.item
 * @param {Function} props.saveIdeaToBeCancelled
 * @returns {JSX.Element}
 */
const Container = ({
  editIdea,
  filterUrl: sourceFilterUrl,
  item,
  saveIdeaToBeCancelled
}) => {
  const dispatch = useDispatch()
  const lng = useLanguage()

  const [, drag] = useDrag(() => ({
    type: 'box',
    item,
    end: (item, monitor) => {
      /** @type {{name: IdeaStates} | null} */
      const dropResult = monitor.getDropResult()
      const sourceOfIdea = item.ideaState
      if (item && dropResult) {
        if (dropResult.name === ideaStates.interrupted.apiValue) {
          saveIdeaToBeCancelled(item, { dropResult, sourceFilterUrl })
          return
        }
        if (item && sourceOfIdea === ideaStates.interrupted.apiValue) {
          handleDrop(
            { ...item, interruptionReason: null },
            dropResult,
            sourceFilterUrl,
            dispatch,
            lng
          )
          return
        }
        handleDrop(item, dropResult, sourceFilterUrl, dispatch, lng)
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }))

  return (
    <div ref={drag} className='kanban-board__item'>
      {/* @ts-ignore */}
      <Item item={item} editIdea={editIdea} />
    </div>
  )
}

Container.propTypes = {
  editIdea: PropTypes.func.isRequired,
  filterUrl: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  saveIdeaToBeCancelled: PropTypes.func.isRequired
}

export default Container
